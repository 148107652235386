import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VotersFilterComponent } from './voters-filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatInputModule } from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatChipsModule} from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
@NgModule({
  declarations: [
    VotersFilterComponent
  ],
  imports: [
    CommonModule,
    FormsModule, 
    ReactiveFormsModule,
    FlexLayoutModule,
    MatInputModule,
    MatRadioModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatIconModule,
    MatSelectModule
  ],
  exports: [
    VotersFilterComponent
  ]
})
export class VotersFilterModule { }
