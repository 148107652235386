<div (mouseenter)="showButton = false"
     (mouseleave)="showButton = false"
     class="card relative hover:shadow-lg trans-shadow flex flex-row  justify-center items-center" fxFlex="100%" fxLayout="row">
  <div [ngClass]="iconClass" class="rounded-full w-12 h-12 flex items-center justify-center cls-icon" fxFlex="15%">
    <mat-icon [svgIcon]="icon"></mat-icon>
  </div>
  <div class="flex flex-col" fxFlex="65%">
  <h2 class="display-2 font-bold value_font_size fs-18">{{ getIndianValue(value) }}</h2>
  <h3 class="subheading-2 value_font_size text-secondary m-0 txt_align mrg_top15">{{ label }}</h3>
</div>

</div>
