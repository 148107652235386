import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalConstants } from '../Common/global-constants';
import { apiConfigUrls } from '../Common/api-config-urls';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MasterService {
  baseUrl = GlobalConstants.ApibaseUrl;
  liveurl = "";
  login: any;
  headersToSend = new HttpHeaders();
  //titleName$: any;

  titleName$ = new BehaviorSubject<string>("");
  constructor(private http: HttpClient) {

  }
  getHeaders() {
    var accessToken = localStorage.getItem('ACCESS_TOKEN') ? localStorage.getItem('ACCESS_TOKEN') : "";

    this.headersToSend = new HttpHeaders();
    this.headersToSend = this.headersToSend
      .set('Authorization', accessToken)
      .set('Accept', 'application/json');
  }

  wardList(data: any) {
    this.getHeaders();

    this.liveurl = this.baseUrl + apiConfigUrls.wardList;
    return this.http.post(this.liveurl, data, {
      headers: this.headersToSend,
    });
  }

  boothList(data: any) {
    this.getHeaders();

    this.liveurl = this.baseUrl + apiConfigUrls.boothList;
    return this.http.post(this.liveurl, data, {
      headers: this.headersToSend,
    });
  }

  bcmList(data: any) {
    this.getHeaders();

    this.liveurl = this.baseUrl + apiConfigUrls.bcmList;
    return this.http.post(this.liveurl, data, {
      headers: this.headersToSend,
    });
  }


  streetList(data: any) {
    this.getHeaders();
    this.liveurl = this.baseUrl + apiConfigUrls.streetList;
    return this.http.post(this.liveurl, data, {
      headers: this.headersToSend,
    });
  }
  voterList(data: any) {
    this.getHeaders();

    this.liveurl = this.baseUrl + apiConfigUrls.voterList;
    return this.http.post(this.liveurl, data, {
      headers: this.headersToSend,
    });
  }
  dashboardList(data:any)              
  {
    this.getHeaders();

    this.liveurl=this.baseUrl+apiConfigUrls.dashboardList;  
    return this.http.post(this.liveurl,data,{
      headers: this.headersToSend,
    });
  }
  streetDropdownList(){
    this.getHeaders();
    this.liveurl = this.baseUrl + apiConfigUrls.streetListDropDown;
    return this.http.post(this.liveurl, {
      headers: this.headersToSend,
    });
  }
  bcmMappingList(data: any) {
    this.getHeaders();

    this.liveurl = this.baseUrl + apiConfigUrls.bcmList;
    return this.http.post(this.liveurl, data, {
      headers: this.headersToSend,
    });
  }

  getUserDetails() {
    return localStorage.getItem("USER_INFO") ? JSON.parse(localStorage.getItem("USER_INFO")) : ""
  }

  familyList(data: any) {
    this.getHeaders();

    this.liveurl = this.baseUrl + apiConfigUrls.familyList;
    return this.http.post(this.liveurl, data, {
      headers: this.headersToSend,
    });
  }
  familyVoterList(data: any) {
    this.getHeaders();
    this.liveurl = this.baseUrl + apiConfigUrls.familyVoterList;
    return this.http.post(this.liveurl, data, {
      headers: this.headersToSend,
    });
  }
}
