import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';

import { Customer } from './interfaces/customer.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { TableColumn } from '../../../../@vex/interfaces/table-column.interface';


import { fadeInUp400ms } from '../../../../@vex/animations/fade-in-up.animation';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { stagger40ms } from '../../../../@vex/animations/stagger.animation';
import { FormBuilder, FormControl, FormGroup, UntypedFormControl  } from '@angular/forms';

import { APICODE } from 'src/app/utils/enums/api-type.enum';
import { localStorageKeys } from 'src/app/utils/enums/localStorage-keys';
import { MasterService } from '../../../services/master.service'
import { LoginService } from 'src/app/services/login.service';
import { SidebarComponent } from 'src/@vex/components/sidebar/sidebar.component';
@Component({
  selector: 'vex-bcmmapping-list',
  templateUrl: './bcmmapping-list.component.html',
  styleUrls: ['./bcmmapping-list.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    }
  ]
}) 

export class BcmmappingListComponent implements OnInit, AfterViewInit{
  

  layoutCtrl = new UntypedFormControl('boxed');


  displayedColumns: string[] = ['Sno', 'booth_number', 'booth_name','member_name', 'start_no','end_no'];
  showButton:boolean;
  @Input()

  columns: TableColumn<Customer>[] = [ 
  ];
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  boothVisible:boolean=true;
  bootharray:any;
  statearray:any;
  icon="mat:pageview";
  constituencyArray:any;
  searchCtrl = new UntypedFormControl();
  bcmmappinglistarray=[];
  resultsLength = 0;
  loaderVisbility = false;
  currentPage=0;

  public filterform!: FormGroup;
  stateObserb!: Observable<any[]>;
  boothObserb!:Observable<any[]>;
  constituencyObserb!: Observable<any[]>;
  bcmlist=new MatTableDataSource([]);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort; 

  @ViewChild('configpanel', { static: true }) configpanel: SidebarComponent;
  orderColName='booth_number';
  orderDirection='asc';
  constructor(private dialog: MatDialog,private formBuilder: FormBuilder,private masterService: MasterService,private loginService: LoginService,) {
    
    this.filterform = this.formBuilder.group({        //Form Builder
      statename: [''],
      constituency:[''],
      booth:['']

  });
  
}



  ngOnInit() {

    this.pageSize = 10;
    this.currentPage = 0;
    this.fnBindBcmMappingData(this.currentPage, this.pageSize)
    
//  this.masterService.titleName$.next("BCM - Voters Mapping");
  }


 




  ngAfterViewInit() {
   // this.dataSource.paginator = this.paginator;

    this.paginator.pageIndex = 0;
    this.pageSize = 10;
    this.currentPage = 0;
    this.fnBindBcmMappingData(this.currentPage, this.pageSize);
  }

  ConstitutuionIdPassing(id:any){
    this.filterform.patchValue({        //Form Builder
      stateID: id.value.stateID,
      constituencyID : id.value.constituencyID,
      booth : id.value.booth ? id.value.booth : []
    });
    this.paginator.pageIndex = 0;
    this.pageSize = 10;
    this.currentPage = 0;
    this.fnBindBcmMappingData(this.currentPage, this.pageSize);
    let title = id.value.zonename ? id.value.constituency + " -> " +id.value.zonename  + " ->BCM - Voters Mapping" : id.value.constituency + "BCM - Voters Mapping";
    this.masterService.titleName$.next(title); 
  }

  getPaginatorData(event: PageEvent): PageEvent {
    return event;
  }


  fnBindBcmMappingData(currentPage: any, pageSize: any) {
    this.loaderVisbility  = true;
    var offset = 0;

    if (currentPage != 0) {
      offset = currentPage * pageSize;
    }
  var data = {
      "stateCode": this.filterform.value.stateID ? Number(this.filterform.value.stateID) : 1,
      "constituencyNumber": this.filterform.value.constituencyID ? this.filterform.value.constituencyID : 1,
      "boothCode": this.filterform.value.booth ? this.filterform.value.booth : [],
       "offSet": offset,
       "limit":pageSize,  
       "orderColumn": this.orderColName,  
       "orderType": this.orderDirection,
       "search":this.searchCtrl.value ? String(this.searchCtrl.value) :"",
   }
    
    this.masterService.bcmMappingList(data).subscribe(
      response => {
        
        this.loaderVisbility = false;
        if (response['body']['status'] == APICODE.SUCCESS_CODE) {
           
          this.bcmmappinglistarray = response['body']['bcm_mapping_list'];
          this.bcmlist = new MatTableDataSource(this.bcmmappinglistarray);

          setTimeout(() => this.bcmlist.paginator = this.paginator);
            setTimeout(() => {
              this.paginator.pageIndex = this.currentPage;

              // Set the total count initially(Call once)
              this.paginator.length = response['body']['total_mapping'];
            });
          
        }
        
        else if (response['body']['status'] == APICODE.UNAUTHORIZED_CODE) {
          this.loginService.generateRefreshToken().subscribe(tokenResponse => {
            if (tokenResponse['body']['status'] == APICODE.SUCCESS_CODE) {
              var accessToken = tokenResponse['body']?tokenResponse['body']['accessToken']?tokenResponse['body']['accessToken']:"":"";
              var refreshToken = tokenResponse['body']?tokenResponse['body']['refreshToken']?tokenResponse['body']['refreshToken']:"":"";            
              localStorage.setItem(localStorageKeys.ACCESS_TOKEN, accessToken);
              localStorage.setItem(localStorageKeys.REFRESH_TOKEN, refreshToken);
              this.fnBindBcmMappingData(this.currentPage, this.pageSize);

            }else
            {
              this.loaderVisbility = false;
            }

          }, error => {
            this.loaderVisbility = false;

          })
        }
        else { 
          this.bcmlist = new MatTableDataSource([]);
          this.resultsLength = 0;
          this.bcmlist.paginator = this.paginator;
          this.bcmlist.sort = this.sort;
        }



      }, error => {
        this.loaderVisbility = false;
      });

  }
  VotersListSearch(){
    this.fnBindBcmMappingData(this.currentPage, this.pageSize);
  }
  openFilterPop($event:any) {
    this.configpanel.open()
  }

  closeFilterPop($event:any) {
    this.configpanel.close()
  }
  sortData(event:any) {
    this.orderColName = event.active ? event.active : "";
    this.orderDirection = event.direction ? event.direction : "";
    this.fnBindBcmMappingData(this.currentPage, this.pageSize);
  }
}
