import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, map, Observable, startWith } from 'rxjs';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { keyValuesToMap } from '@angular/flex-layout/extended/style/style-transforms';

@Component({
  selector: 'vex-voters-filter',
  templateUrl: './voters-filter.component.html',
  styleUrls: ['./voters-filter.component.scss']
})
export class VotersFilterComponent {
  public filterform!: FormGroup;
  @Output() public SelectedFilters: EventEmitter<any> = new EventEmitter<any>();
  baseArray:any[] = [];
  @Input() totalGenderList:any[]=[];
  @Input() verificationStatusList:any[]=[];
  ageList:any[]=[];
  educationList:any[]=[];
  job:any[]=[];
  allJob:any = [];
  pension:any[]=[];
  professionTypeList:any[]=[];
  voterStatus:any[]=[];
  religionList:any[]=[];
  specialCases:any[]=[];
  partyMemberList:any[]=[];
  differentlyAbled:any[]=[];
  
  genders: any[] = [];
  religions: string[] = [];
  verificate: any[] = [];
  votersStatus: any[] = [];
  eduQualif: any[] = [];
  profession: any[] = [];
  professionjob: any[] = [];
  pensionDisp: any[] = [];
  ageDisp: any[] = [];
  partyMember: any[] = [];
  specialCase: any[] = [];
  
  genderArray = [];
  religionArray = []
  verifiArray = []
  votersArray = []
  eduArray = []
  professionArray = []
  jobArray = []
  pensionArray = []
  ageArray = []
  partyArray: any[] = [];
  specialArray: any[] = [];

  separatorKeysCodes: number[] = [ENTER, COMMA];
  genderCtrl = new FormControl('');
  ageCtrl= new FormControl('');
  religionCtrl = new FormControl('');
  verfCtrl = new FormControl('');
  voterCtrl= new FormControl('');
  eduCtrl= new FormControl('');
  professCtrl= new FormControl('');
  jobCtrl= new FormControl('');
  pensionCtrl= new FormControl('');
  partyCtrl = new FormControl('');

  filteredGender: Observable<any[]>;
  filteredReligion: Observable<any[]>;
  filteredEdu: Observable<any[]>;
  filteredVerf: Observable<any[]>;
  filteredVoter: Observable<any[]>;
  filteredProfession: Observable<any[]>;
  filteredParty: Observable<any[]>;
  filteredJob: Observable<any[]>;
  filteredPension: Observable<any[]>;
  filteredAge: Observable<any[]>;
  specialObserb: Observable<any[]>;

  @ViewChild('genderInput') genderInput: ElementRef<HTMLInputElement>;
  @ViewChild('AgeInput') AgeInput: ElementRef<HTMLInputElement>;
  @ViewChild('religionInput') religionInput: ElementRef<HTMLInputElement>;
  @ViewChild('verifInput') verifInput: ElementRef<HTMLInputElement>;
  @ViewChild('voterInput') voterInput: ElementRef<HTMLInputElement>;
  @ViewChild('eduInput') eduInput: ElementRef<HTMLInputElement>;
  @ViewChild('ProfessInput') ProfessInput: ElementRef<HTMLInputElement>;
  @ViewChild('JobInput') JobInput: ElementRef<HTMLInputElement>;
  @ViewChild('PensionInput') PensionInput: ElementRef<HTMLInputElement>;
  @ViewChild('PartyInput') PartyInput: ElementRef<HTMLInputElement>;

  
  constructor( private formBuilder: FormBuilder) {
    this.filterform = this.formBuilder.group({        //Form Builder
      ageFrom: [''],
      ageTo: [''],
      age:[''],
      eduQualification: [''],
      mobileno:[''],
      partyMember:[''],
      willingToJoin: [''],
      gender: [],
      verificationStatus: [],
      differentlyAbled: [""],
      differentlyAbledCode: [0],
      appliedPartyMember: [],
      voterStatus: [''],
      religions: [''],
      professionL1: [''],
      job: [''],
      pension: [''],
      specialCase: [''],
      specialCaseCode:[0]
    });
  }

  ngOnInit() {
    this.filterform.valueChanges.pipe(debounceTime(400)).subscribe(() => {
      // this.filterform.patchValue({
      //   gender:  this.genderList.flatMap((el:any) => el.code)
      // })
      this.SelectedFilters.emit(this.filterform.value);
    });
  }

  _filter(value: any, txtValue:string): any[] {
    if(txtValue == "gender") {
      const filterValue = value && value.name ? value.name.toLowerCase() : value ? value.toLowerCase() : '';

      return this.totalGenderList.filter(gender => String(gender.name).toLowerCase().includes(filterValue));
    }
    if(txtValue == "age") {
      const filterValue = value && value.name ? value.name.toLowerCase() : value ? value.toLowerCase() : '';

      return this.ageList.filter(gender => String(gender.name).toLowerCase().includes(filterValue));
    }
    if(txtValue == "religion") {
      const filterValue = value && value.name ? value.name.toLowerCase() : value ? value.toLowerCase() : '';
      return this.religionList.filter(gender => String(gender.name).toLowerCase().includes(filterValue));
    }
    if(txtValue == "verifications") {
      const filterValue = value && value.name ? value.name.toLowerCase() : value ? value.toLowerCase() : '';

      return this.verificationStatusList.filter(gender => String(gender.name).toLowerCase().includes(filterValue));
    }
    if(txtValue == "voters") {
      const filterValue = value && value.name ? value.name.toLowerCase() : value ? value.toLowerCase() : '';

      return this.voterStatus.filter(gender => String(gender.name).toLowerCase().includes(filterValue));
    }
    if(txtValue == "eduQualif") { 
      const filterValue = value && value.name ? value.name.toLowerCase() : value ? value.toLowerCase() : '';

      return this.educationList.filter(gender => String(gender.name).toLowerCase().includes(filterValue));
    }
    if(txtValue == "profession") { 
      const filterValue = value && value.name ? value.name.toLowerCase() : value ? value.toLowerCase() : '';

      return this.professionTypeList.filter(gender => String(gender.name).toLowerCase().includes(filterValue));
    }
    if(txtValue == "job") { 
      const filterValue = value && value.name ? value.name.toLowerCase() : value ? value.toLowerCase() : '';

      return this.job.filter(gender => String(gender.name).toLowerCase().includes(filterValue));
    }
    if(txtValue == "pension") { 
      const filterValue = value && value.name ? value.name.toLowerCase() : value ? value.toLowerCase() : '';

      return this.pension.filter(gender => String(gender.name).toLowerCase().includes(filterValue));
    }
    if(txtValue == "party") { 
      const filterValue = value && value.name ? value.name.toLowerCase() : value ? value.toLowerCase() : '';

      return this.partyMemberList.filter(gender => String(gender.name).toLowerCase().includes(filterValue));
    }
    if(txtValue == "special") { 
      const filterValue = typeof(value) == 'string' ? value && value['name'] ? value['name'].toLowerCase() : value ? value.toLowerCase() : '' : "";
      // if(filterValue){
        // this.filterform.patchValue({
        //   specialCase: "",
        //   specialCaseCode: 0,
        // });
      // }
      return this.specialCases.filter(gender => String(gender.name).toLowerCase().includes(filterValue));
    }
  }

  ageOnly(event)  {
    try {
      const regex = /^[0-9]*$/;
      const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (!regex.test(key)) {
        event.preventDefault();
        return false;
      }
      return true;
    }
    catch (error) {
    }
  }

  remove(gender: string, txtVal: string): void {
    if(txtVal == 'gender') {
      const index = this.genders.indexOf(gender);

      if (index >= 0) {
        this.genderArray[index] ? this.totalGenderList.push(this.genderArray[index]) : [];
        this.genderArray.splice(index, 1);
        this.genders.splice(index, 1);
      }
      this.filterform.patchValue({
        gender : this.genderArray
      });
      this.loadChips("gender");
    }

    if(txtVal == 'age') {
      const index = this.ageDisp.indexOf(gender);

      if (index >= 0) {
        this.ageArray[index] ? this.ageList.push(this.ageArray[index]) : [];
        this.ageArray.splice(index, 1);
        this.ageDisp.splice(index, 1);
      }
      this.filterform.patchValue({
        age : this.ageArray
      });
      this.ageList.sort((a: any, b: any) => {  //sorting
        if (Number(a.code) > Number(b.code))
            return 1;
        else
            return -1;
      });
      this.loadChips("age");
    }

    if(txtVal == 'religion') {
      const index = this.religions.indexOf(gender);

      if (index >= 0) {
        this.religionArray[index] ? this.religionList.push(this.religionArray[index]) : [];
        this.religionArray.splice(index, 1);
        this.religions.splice(index, 1);
        this.filterform.patchValue({
          religions : this.religionArray
        })
      }
      this.loadChips("religion");
    }

    if(txtVal == 'verifications') {
      const index = this.verificate.indexOf(gender);

      if (index >= 0) {
        this.verifiArray[index] ? this.verificationStatusList.push(this.verifiArray[index]) : [];
        this.verifiArray.splice(index, 1);
        this.verificate.splice(index, 1);
        this.filterform.patchValue({
          verificationStatus : this.genderArray
        })
      }
      this.loadChips("verifications");
    }

    if(txtVal == 'voters') {
      const index = this.votersStatus.indexOf(gender);

      if (index >= 0) {
        this.votersArray[index] ? this.voterStatus.push(this.votersArray[index]) : [];
        this.votersArray.splice(index, 1);
        this.votersStatus.splice(index, 1);
        this.filterform.patchValue({
          voterStatus : this.votersArray
        })
      }
      this.loadChips("voters");
    }

    if(txtVal == 'eduQualif') {
      const index = this.eduQualif.indexOf(gender);

      if (index >= 0) {
        this.eduArray[index] ? this.educationList.push(this.eduArray[index]) : [];
        this.eduArray.splice(index, 1);
        this.eduQualif.splice(index, 1);
        this.filterform.patchValue({
          eduQualification : this.eduArray
        })
      }
      this.loadChips("eduQualif");
    }
    if(txtVal == 'profession') {
      const index = this.profession.indexOf(gender);

      if (index >= 0) {
        this.professionArray[index] ? this.professionTypeList.push(this.professionArray[index]) : [];
        let filter = this.job.filter(jobs => jobs.profession_type != this.professionArray[index].code);
        this.professionArray.splice(index, 1);
        this.profession.splice(index, 1);
        this.filterform.patchValue({
          professionL1 : this.professionArray
        })
        
        this.professionTypeList.sort((a: any, b: any) => {  //sorting
          if (Number(a.code) > Number(b.code))
              return 1;
          else
              return -1;
        });
        this.professionjob = [];
        this.JobInput.nativeElement.value = '';
        this.jobCtrl.setValue(null);
        this.jobArray = [];
        this.job= filter
        this.filterform.patchValue({
          job : this.jobArray
        })
        this.loadChips("job");
      }
      this.loadChips("profession");
    }
    if(txtVal == 'job') {
      const index = this.professionjob.indexOf(gender);

      if (index >= 0) {
        this.jobArray[index] ? this.job.push(this.jobArray[index]) : [];
        this.jobArray.splice(index, 1);
        this.professionjob.splice(index, 1);
        this.filterform.patchValue({
          job : this.jobArray
        })
      }
      this.loadChips("job");
    }
    if(txtVal == 'pension') {
      const index = this.pensionDisp.indexOf(gender);

      if (index >= 0) {
        this.pensionArray[index] ? this.pension.push(this.pensionArray[index]) : [];
        this.pensionArray.splice(index, 1);
        this.pensionDisp.splice(index, 1);
        this.filterform.patchValue({
          pension : this.pensionArray
        })
      }
      this.loadChips("pension");
    }
    if(txtVal == 'party') {
      const index = this.partyMember.indexOf(gender);

      if (index >= 0) {
        this.partyArray[index] ? this.partyMemberList.push(this.partyArray[index]) : [];
        this.partyArray.splice(index, 1);
        this.partyMember.splice(index, 1);
        this.filterform.patchValue({
          partyMember : this.partyArray
        })
      }
      this.loadChips("party");
    }
  }

  selected(event: MatAutocompleteSelectedEvent, txtValue:string): void {
    if(txtValue == "gender") {
      this.genders.push(event.option.viewValue);
      this.genderInput.nativeElement.value = '';
      this.genderCtrl.setValue(null);
      this.genderArray.push(event.option.value);
      this.filterform.patchValue({
        gender : this.genderArray
      });
      this.totalGenderList = this.totalGenderList.filter(ward => String(ward.code) != event.option.value.code);
      this.loadChips("gender");
    }

    if(txtValue == "age") {
      this.ageDisp.push(event.option.viewValue);
      this.AgeInput.nativeElement.value = '';
      this.ageCtrl.setValue(null);
      this.ageArray.push(event.option.value);
      this.filterform.patchValue({
        age : this.ageArray
      });
      this.ageList = this.ageList.filter(ward => String(ward.code) != event.option.value.code);
      this.loadChips("age");
    }

    if(txtValue == "religion") {
      this.religions.push(event.option.viewValue);
      this.religionInput.nativeElement.value = '';
      this.religionCtrl.setValue(null);
      this.religionArray.push(event.option.value);
      this.filterform.patchValue({
        religions : this.religionArray
      });
      this.religionList = this.religionList.filter(religion => String(religion.code) != event.option.value.code);
      this.loadChips("religion");
    }

    if(txtValue == "verifications") {
      this.verificate.push(event.option.viewValue);
      this.verifInput.nativeElement.value = '';
      this.verfCtrl.setValue(null);
      this.verifiArray.push(event.option.value);
      this.verificationStatusList = this.verificationStatusList.filter(ward => String(ward.code) != event.option.value.code);
      this.filterform.patchValue({
        verificationStatus : this.verifiArray
      })
      this.loadChips("verifications");
    }

    if(txtValue == "voters") {
      this.votersStatus.push(event.option.viewValue);
      this.voterInput.nativeElement.value = '';
      this.voterCtrl.setValue(null);
      this.votersArray.push(event.option.value);
      this.voterStatus = this.voterStatus.filter(ward => String(ward.code) != event.option.value.code);
      this.filterform.patchValue({
        voterStatus : this.votersArray
      })
      this.loadChips("voters");
    }

    if(txtValue == "eduQualif") {
      this.eduQualif.push(event.option.viewValue);
      this.eduInput.nativeElement.value = '';
      this.eduCtrl.setValue(null);
      this.eduArray.push(event.option.value);
      this.educationList = this.educationList.filter(ward => String(ward.code) != event.option.value.code);
      this.filterform.patchValue({
        eduQualification : this.eduArray
      })
      this.loadChips("eduQualif");
    }
    if(txtValue == "profession") {
      this.profession.push(event.option.viewValue);
      this.ProfessInput.nativeElement.value = '';
      this.professCtrl.setValue(null);
      this.professionArray.push(event.option.value);
      this.professionTypeList = this.professionTypeList.filter(ward => String(ward.code) != event.option.value.code);
      this.filterform.patchValue({
        professionL1 : this.professionArray
      })
      let filter = this.allJob.filter(job => job.profession_type == event.option.value.code);
      if(filter.length > 0) this.job = [...this.job,...filter];
      this.loadChips("profession");
      this.loadChips("job");
    }
    if(txtValue == "job") {
      this.professionjob.push(event.option.viewValue);
      this.JobInput.nativeElement.value = '';
      this.jobCtrl.setValue(null);
      this.jobArray.push(event.option.value);
      this.job = this.job.filter(ward => String(ward.code) != event.option.value.code);
      this.filterform.patchValue({
        job : this.jobArray
      })
      this.loadChips("job");
    }
    if(txtValue == "pension") {
      this.pensionDisp.push(event.option.viewValue);
      this.PensionInput.nativeElement.value = '';
      this.pensionCtrl.setValue(null);
      this.pensionArray.push(event.option.value);
      this.pension = this.pension.filter(pension => String(pension.code) != event.option.value.code);
      this.filterform.patchValue({
        pension : this.pensionArray
      })
      this.loadChips("pension");
    }
    if(txtValue == "party") {
      this.partyMember.push(event.option.viewValue);
      this.PartyInput.nativeElement.value = '';
      this.partyCtrl.setValue(null);
      this.partyArray.push(event.option.value);
      this.partyMemberList = this.partyMemberList.filter(pension => String(pension.code) != event.option.value.code);
      this.filterform.patchValue({
        partyMember : this.partyArray
      })
      this.loadChips("party");
    }
  }

  changeZoneValue(value: any) {
    const filterValue = value.toLowerCase();
    let filter = this.specialCases.filter((option: any) => String(option.label).toLowerCase() == (filterValue));
    if (filter && filter.length > 0) {
      this.filterform.patchValue({
        specialCase: filter[0].label,
        specialCaseCode: filter[0].code,
      });
    }
    else 
      this.filterform.patchValue({
        specialCase: "",
        specialCaseCode: 0,
      });
  }
  changeZone() {
    if(this.filterform.value.specialCase == ""){
      this.filterform.patchValue({
        specialCase: "",
        specialCaseCode: 0,
      });
    }
  }

  public bindDetails(allDetails) {
    this.clearClick();
    this.baseArray = allDetails;
    this.totalGenderList = allDetails.gender || allDetails.genderList;
    this.verificationStatusList = allDetails.verificationStatusList;
    this.ageList = allDetails.ageList;
    this.educationList = allDetails.educationList;
    // this.job = allDetails.job;
    this.allJob = allDetails.job;

    this.pension= allDetails.pension;
    this.professionTypeList=allDetails.professionTypeList;
    this.voterStatus=allDetails.voterStatus;
    this.religionList = allDetails.religionList;
    this.specialCases = allDetails.specialCases;
    this.partyMemberList = allDetails.partyMemberList;
    // this.verificationStatusList = allDetails.verificate;
    this.differentlyAbled = allDetails.differentlyAbled;

    this.filteredGender = this.genderCtrl.valueChanges.pipe(
      startWith(''),
      map((gender: string | null) => (gender ? this._filter(gender,'gender') : this.totalGenderList.slice())),
    );

    this.filteredAge = this.ageCtrl.valueChanges.pipe(
      startWith(''),
      map((gender: string | null) => (gender ? this._filter(gender,'age') : this.ageList.slice())),
    );
    this.filteredReligion = this.religionCtrl.valueChanges.pipe(
      startWith(''),
      map((gender: string | null) => (gender ? this._filter(gender,'religion') : this.religionList.slice())),
    );

    this.filteredEdu = this.religionCtrl.valueChanges.pipe(
      startWith(''),
      map((gender: string | null) => (gender ? this._filter(gender,'eduQualif') : this.educationList.slice())),
    );

    this.filteredVerf = this.verfCtrl.valueChanges.pipe(
      startWith(''),
      map((gender: string | null) => (gender ? this._filter(gender,'verifications') : this.verificationStatusList.slice())),
    );
    
    this.filteredVoter = this.voterCtrl.valueChanges.pipe(
      startWith(''),
      map((gender: string | null) => (gender ? this._filter(gender,'voters') : this.voterStatus.slice())),
    );
    
    this.filteredProfession = this.professCtrl.valueChanges.pipe(
      startWith(''),
      map((gender: string | null) => (gender ? this._filter(gender,'profession') : this.professionTypeList.slice())),
    );
    this.filteredJob = this.jobCtrl.valueChanges.pipe(
      startWith(''),
      map((gender: string | null) => (gender ? this._filter(gender,'job') : this.job.slice())),
    );
    this.filteredPension = this.pensionCtrl.valueChanges.pipe(
      startWith(''),
      map((gender: string | null) => (gender ? this._filter(gender,'pension') : this.pension.slice())),
    );
    this.specialObserb = this.filterform.get('specialCase').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value,'special'))
    );
    this.filteredParty = this.partyCtrl.valueChanges.pipe(
      startWith(''),
      map((gender: string | null) => (gender ? this._filter(gender,'party') : this.partyMemberList.slice())),
    );
  }

  add(event: MatChipInputEvent, txtValue:string): void {
    const value = (event.value || '').trim();

    if(txtValue == "gender") {
      // Add our gender
      if (value) {
        this.genders.push(value);
        this.genderArray.push(value)
      }

      // Clear the input value
      event.chipInput!.clear();
      this.genderCtrl.setValue(null);
      this.filterform.patchValue({
        gender : this.genderArray
      })
    }

    if(txtValue == "age") {
      // Add our gender
      if (value) {
        this.ageDisp.push(value);
        this.ageArray.push(value)
      }

      // Clear the input value
      event.chipInput!.clear();
      this.ageCtrl.setValue(null);
      this.filterform.patchValue({
        age : this.ageArray
      })
    }
    if(txtValue == "religion") {
      // Add our gender
      if (value) {
        this.religions.push(value);
        this.religionArray.push(value)
      }
      this.filterform.patchValue({
        religions : this.religionArray
      })
      // Clear the input value
      event.chipInput!.clear();
      this.religionCtrl.setValue(null);
    }

    if(txtValue == "verifications") {
      // Add our gender
      if (value) {
        this.verificate.push(value);
        this.verifiArray.push(value)
      }

      // Clear the input value
      event.chipInput!.clear();
      this.verfCtrl.setValue(null);
      this.filterform.patchValue({
        voters : this.verifiArray
      })
    }

    if(txtValue == "voters") {
      // Add our gender
      if (value) {
        this.votersStatus.push(value);
        this.votersArray.push(value)
      }

      // Clear the input value
      event.chipInput!.clear();
      this.voterCtrl.setValue(null);
      this.filterform.patchValue({
        voterStatus : this.votersArray
      })
    }
    if(txtValue == "profession") {
      // Add our gender
      if (value) {
        this.votersStatus.push(value);
        this.votersArray.push(value)
      }

      // Clear the input value
      event.chipInput!.clear();
      this.professCtrl.setValue(null);
      this.filterform.patchValue({
        voterStatus : this.votersArray
      })
    }
    if(txtValue == "job") {
      // Add our gender
      if (value) {
        this.professionjob.push(value);
        this.jobArray.push(value)
      }

      // Clear the input value
      event.chipInput!.clear();
      this.jobCtrl.setValue(null);
      this.filterform.patchValue({
        job : this.jobArray
      })
    }
    if(txtValue == "pension") {
      // Add our gender
      if (value) {
        this.pensionDisp.push(value);
        this.pensionArray.push(value)
      }

      // Clear the input value
      event.chipInput!.clear();
      this.pensionCtrl.setValue(null);
      this.filterform.patchValue({
        pension : this.pensionArray
      })
    }
    if(txtValue == "party") {
      // Add our gender
      if (value) {
        this.partyMember.push(value);
        this.partyArray.push(value)
      }

      // Clear the input value
      event.chipInput!.clear();
      this.partyCtrl.setValue(null);
      this.filterform.patchValue({
        partyMember : this.partyArray
      })
    }
  }

  changeDiffAbled(diffVal:any)
  {
     if(!diffVal.value)
      return;
    
      let zoneFilter = this.differentlyAbled.filter((option: any) => option.label == (diffVal.value));
      if (zoneFilter && zoneFilter.length > 0) {
        this.filterform.patchValue({
          differentlyAbledCode: zoneFilter[0].code,
          differentlyAbled: diffVal.value,
        });
      }
  }
  
  loadChips( txtValue:string) {
    if(txtValue == "verifications")
      this.filteredVerf = this.verfCtrl.valueChanges.pipe(
        startWith(''),
        map((gender: string | null) => this._filter(gender,'verifications')),
      );
    if(txtValue == "gender")
      this.filteredGender = this.genderCtrl.valueChanges.pipe(
        startWith(''),
        map((gender: string | null) => this._filter(gender,'gender')),
      );
    if(txtValue == "voters")
      this.filteredVoter = this.voterCtrl.valueChanges.pipe(
        startWith(''),
        map((gender: string | null) => this._filter(gender,'voters')),
      );
      
    if(txtValue == "religion")
      this.filteredReligion = this.religionCtrl.valueChanges.pipe(
        startWith(''),
        map((gender: string | null) => this._filter(gender,'religion')),
      );
    if(txtValue == "eduQualif")
      this.filteredEdu = this.eduCtrl.valueChanges.pipe(
        startWith(''),
        map((gender: string | null) => this._filter(gender,'eduQualif')),
      );
    if(txtValue == "profession")
      this.filteredProfession = this.professCtrl.valueChanges.pipe(
        startWith(''),
        map((gender: string | null) => this._filter(gender,'profession')),
      );
    if(txtValue == "job")
      this.filteredJob = this.jobCtrl.valueChanges.pipe(
        startWith(''),
        map((gender: string | null) => this._filter(gender,'job')),
      );
    if(txtValue == "pension")
      this.filteredPension = this.pensionCtrl.valueChanges.pipe(
        startWith(''),
        map((gender: string | null) => this._filter(gender,'pension')),
      );
      if(txtValue == "age")
      this.filteredAge = this.ageCtrl.valueChanges.pipe(
        startWith(''),
        map((gender: string | null) => this._filter(gender,'age')),
      );

      if(txtValue == "party")
        this.filteredParty = this.partyCtrl.valueChanges.pipe(
          startWith(''),
          map((gender: string | null) => this._filter(gender,'party')),
        );
      if(txtValue == "special") 
        this.specialObserb = this.filterform.get('specialCase').valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value,'special'))
        );
      
  }

  clearClick() {
    this.verfCtrl.setValue(null);
    this.jobCtrl.setValue(null);
    this.pensionCtrl.setValue(null);
    this.genderCtrl.setValue(null);
    this.religionCtrl.setValue(null);
    this.voterCtrl.setValue(null);
    this.ageCtrl.setValue(null);
    this.eduCtrl.setValue(null);
    this.ageCtrl.setValue(null);
    this.professCtrl.setValue(null);
    this.partyCtrl.setValue(null);

    this.religionArray=[];
    this.genders=[];
    this.religions= [];
    this.verificate = [];
    this.votersStatus = [];
    this.eduQualif = [];
    this.profession = [];
    this.professionjob = [];
    this.pensionDisp = [];
    this.ageDisp = [];
    
    this.genderArray = [];
    this.religionArray = [];
    this.verifiArray = [];
    this.votersArray = [];
    this.eduArray = [];
    this.professionArray = [];
    this.jobArray = [];
    this.pensionArray = [];
    this.ageArray = [];
    
    this.specialCases =[];
    this.partyMemberList =[];
    this.partyMember = [];
    this.specialCase = [];
    this.partyArray = [];
    this.specialArray = [];
    this.filterform.patchValue({        //Form Builder
      ageFrom: [''],
      ageTo: [''],
      eduQualification: [''],
      mobileno:[''],
      partyMember:[],
      willingToJoin: [-1],
      gender: [],
      verificationStatus: [],
      appliedPartyMember: [-1],
      voterStatus: [''],
      religions: [''],
      professionL1: [''],
      job: [''],
      pension: [''],
      specialCase: [''],
      specialCaseCode:[0],
      differentlyAbled: [""],
      differentlyAbledCode: [0],
    });
    // this.bindDetails(this.baseArray)
  }
}
