<div>

  <div class="dropdown-footer flex items-center justify-between" *ngIf="showLogoutDropdown">
   
    <a (click)="close()" color="primary" mat-button>Logout</a>
  </div>
</div>

<mat-menu #statusMenu="matMenu" xPosition="before" yPosition="below">
  <button (click)="setStatus(status)"
          *ngFor="let status of statuses; trackBy: trackById"
          mat-menu-item>
    <mat-icon [ngClass]="status.colorClass" [svgIcon]="status.icon"></mat-icon>
    <span>{{ status.label }}</span>
  </button>
</mat-menu>


<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
 
</mat-menu>
