import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { HttpClientModule } from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { WidgetCardModule } from './pages/apps/widget-card/widget-card.module';
import { BcmmappingListModule  } from '../app/pages/apps/bcmmapping-list/bcmmapping-list.module';
import {WidgetQuickValueCenterModule } from '../@vex/components/widgets/widget-quick-value-center/widget-quick-value-center.module' 
import { MatIconModule } from '@angular/material/icon'; 
import { DashboardModule } from '../app/pages/apps/dashboard/dashboard.module';

@NgModule({
  declarations: [AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    WidgetCardModule,
    // Vex
    DashboardModule,
    VexModule,
    CustomLayoutModule,BcmmappingListModule,WidgetQuickValueCenterModule,MatIconModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
