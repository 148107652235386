<vex-page-layout>


  <!-- <vex-page-layout-content [class.container]="layoutCtrl.value === 'boxed'"
    [class.px-gutter]="layoutCtrl.value === 'fullwidth'" class="-mt-6">
    <div class="card overflow-auto -mt-16 table_top">
      <div class="
          bg-app-bar
          px-6
          h-16
          border-b
          sticky
          left-0
          flex
          items-center
          disp_flex
        ">
        <h2 class="
            title
            my-0
            ltr:pr-4
            rtl:pl-4
            ltr:mr-4
            rtl:ml-4
            ltr:border-r
            rtl:border-l
            hidden
            sm:block
            flex-none flex_updates
            disp_flex
          ">
        
          <div class="
              bg-foreground
              rounded-full
              border
              px-4
              max-w-[300px]
              flex-auto flex
              items-center
              border border-gray-300
            ">
            <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
            <input formControlName="search_value" (keyup.enter)="VotersListSearch()"
              class="px-4 py-2 border-0 outline-none w-full bg-transparent" placeholder="Search by Name, Voter ID"
              type="search" />
          </div>
          </h2>
          </div>
    </div>
  </vex-page-layout-content> -->
  <vex-page-layout-content [class.container]="layoutCtrl.value === 'boxed'"
    [class.px-gutter]="layoutCtrl.value === 'fullwidth'" class="-mt-6">

    <div class="card overflow-auto -mt-16 table_top">
      <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center disp_flex">

        <div
          class="bg-foreground rounded-full border px-4 max-w-[300px] flex-auto flex items-center border border-gray-300">
          <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
          <input [formControl]="searchCtrl" (keyup.enter)="VotersListSearch()"  (search)="VotersListSearch()" 
            class="px-4 py-2 border-0 outline-none w-full bg-transparent" placeholder="Search By Part No., Member"
            type="search">

            
        </div>
        <span class="flex_space"></span>
        <div class="pad-50">
          <span><button mat-raised-button color="warn">Import</button> </span>
          <span><button mat-raised-button class="mrg-lf24" color="warn">Download Template</button></span>
        </div>
      </div>

      </div>
        <div fxLayout="column">
          <div *ngIf="loaderVisbility == true" class="overlay">
            <div class="center">
              <mat-progress-spinner
                diameter="50"
                mode="indeterminate"
                color="accent"
              >
              </mat-progress-spinner>
            </div>
          </div>
          <table mat-table [dataSource]="bcmlist" matSort (matSortChange)="sortData($event)" class="mrg-25" fxFlex="100%">
            <ng-container matColumnDef="Sno">
              <th mat-header-cell *matHeaderCellDef class="cls-cell ml-center" class="bgheadingcolor">
                S.No.
              </th>
              <td class="ml-center" mat-cell *matCellDef="let item; let i = index">
                {{ paginator.pageIndex * paginator.pageSize + (i + 1) }}
              </td>
            </ng-container>

            <ng-container matColumnDef="booth_number">
              <th mat-header-cell *matHeaderCellDef class="cls-cell ml-center"    mat-sort-header class="bgheadingcolor">
                Part No.
              </th>
              <td class="ml-center" mat-cell *matCellDef="let item">
                {{ item.booth_number }}
              </td>
            </ng-container>

            <ng-container matColumnDef="booth_name">
              <th mat-header-cell *matHeaderCellDef class="cls-cell ml-center"    mat-sort-header class="bgheadingcolor">
                Booth Name
              </th>
              <td class="ml-center" mat-cell *matCellDef="let item">
                {{ item.booth_name }}
              </td>
            </ng-container>
            <ng-container matColumnDef="member_name">
              <th mat-header-cell *matHeaderCellDef class="cls-cell ml-center"    mat-sort-header class="bgheadingcolor">
                BCM Name
              </th>
              <td class="ml-center" mat-cell *matCellDef="let item">
                {{ item.member_name }}
              </td>
            </ng-container>

            <ng-container matColumnDef="start_no">
              <th mat-header-cell *matHeaderCellDef class="cls-cell ml-center"    mat-sort-header class="bgheadingcolor">
                S.No. From
              </th>
              <td class="ml-center" mat-cell *matCellDef="let item">
                {{ item.start_no }}
              </td>
            </ng-container>

            <ng-container matColumnDef="end_no">
              <th mat-header-cell *matHeaderCellDef class="cls-cell ml-center"    mat-sort-header class="bgheadingcolor">
                S.No. To
              </th>
              <td class="ml-center" mat-cell *matCellDef="let item">
                {{ item.end_no }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" class="example-element-row"
              class="column_height"></tr>
          </table>

          <div fxLayout="row" fxFlex="100%">
            <mat-paginator (page)="getPaginatorData($event)" #paginator [length]="resultsLength" [pageSize]="pageSize"
              fxFlex="100%" [pageSizeOptions]="[10, 50, 100]">
            </mat-paginator>
          </div>
        </div>
  </vex-page-layout-content>
</vex-page-layout>
<vex-config-panel-toggle class="z-index200" (openConfig)="openFilterPop($event)" tooltip="Apply Filters"
  icon="mat:filter_list"></vex-config-panel-toggle>

<vex-sidebar #configpanel [invisibleBackdrop]="true" position="right">
  <vex-constituency-filter style="width: 100%" [boothVisible]="boothVisible" (closeConfig)="closeFilterPop($event)"
    (IdDetails)="ConstitutuionIdPassing($event)"></vex-constituency-filter>
</vex-sidebar>