import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { VexRoutes } from '../@vex/interfaces/vex-route.interface';
import { AuthGuard } from './services/auth.guards'
import { RouterPath } from 'src/app/utils/enums/route.enum';
//import { AgmCoreModule } from '@agm/core';
const routes: VexRoutes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: CustomLayoutComponent,
    loadChildren: () => import('./pages/apps/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: RouterPath.LOGIN,
    loadChildren: () =>
      import('./pages/pages/auth/login/login.module').then((m) => m.LoginModule),
  },

  {
    path: '',
    canActivate: [AuthGuard],
    component: CustomLayoutComponent,
    children: [

      // {
      //   path: RouterPath.STREETLIST,
      //   loadChildren: () => import('./pages/apps/street-list/street-list.module').then(m => m.StreetListModule),
      // },
      {
        path: RouterPath.VOTERSLIST,
        loadChildren: () => import('./pages/apps/voters-list/voters-list.module').then(m => m.VotersListModule),
      },
      {
        path: RouterPath.WARDLIST,
        loadChildren: () => import('./pages/apps/ward-list/ward-list.module').then(m => m.WardListModule),
      },
      {
        path: RouterPath.BOOTHLIST,
        loadChildren: () => import('./pages/apps/booth-list/booth-list.module').then(m => m.BoothListModule),
      },
      {
        path: RouterPath.BCMMAPPINGLIST,
        loadChildren: () => import('./pages/apps/bcmmapping-list/bcmmapping-list.module').then(m => m.BcmmappingListModule),
      },
      {
        path: RouterPath.FAMILYLIST,
        loadChildren: () => import('./pages/apps/family-list/family-list.module').then(m => m.FamilyListModule),
      },
      {
        path: RouterPath.FAMILYDETAILS,
        loadChildren: () => import('./pages/apps/family-details/family-details.module').then(m => m.FamilyDetailsModule),
      },
      {
        path: RouterPath.DASHBOARD,
        loadChildren: () => import('./pages/apps/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: RouterPath.BCMLIST,
        loadChildren: () => import('./pages/apps/bcm-list/bcm-list.module').then(m => m.BcmListModule),
      }
    
  
    ]
  },
  {
    path: RouterPath.LOGIN,
    loadChildren: () =>
      import('./pages/pages/auth/login/login.module').then((m) => m.LoginModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule,
   ]
})
export class AppRoutingModule {
}
