

export class GlobalConstants {

    // public static ApibaseUrl = "http://localhost:4202/";
    //public static ApibaseUrl = "https://dmkdevapi.trio-s.com/";  //Dev
    // public static ApibaseUrl = "https://dmkuatapi.trio-s.com/";  //UAT
    public static ApibaseUrl = "https://dmkapi.trio-s.com/";  //UAT
   
}
   