export const apiConfigUrls = {
    Login: 'api/login/loginUser',
    refreshToken: 'api/login/refreshToken',
    wardList: 'api/ward/wardList',
    boothList: 'api/booth/boothList',
    streetList: 'api/street/streetList',
    voterList: 'api/voter/voterList',
    bcmList: 'api/voter/voterList',
    streetListDropDown: 'api/booth/ddMasterList',
    familyList: 'api/family/familyList',
    familyVoterList: 'api/family/familyVoterList',
    dashboardList : 'api/dashboard/getChartData'
};
