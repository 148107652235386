import { Component } from '@angular/core';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
@Component({
  selector: 'vex-confirm-dialog',template: `
  <div mat-dialog-title class="flex items-center justify-between">
    <div>Logout</div>
    <button type="button" mat-icon-button (click)="close('No answer')" tabindex="-1">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <mat-dialog-content>
    <p>Are you sure you want to logout?</p>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button color="primary" (click)="close('Yes')">Yes</button>
    <button mat-button (click)="close('No')">No</button>
  </mat-dialog-actions>
`
})
export class ConfirmDialogComponent {

  constructor(private dialogRef: MatDialogRef<ConfirmDialogComponent>,private router: Router) {
  }

  result: string;
  close(answer: string) {
    if (answer=="No"){
      this.dialogRef.close(answer);
    }
    if(answer=="Yes")
    { 
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigate(['/login']); 
      this.dialogRef.close(answer);
    }
    if(answer=="No answer")
    { 
      this.dialogRef.close(answer);
    }
    
  }
 

}
