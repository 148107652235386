import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/pages/apps/confirm-dialog/confirm-dialog.component';
import { LoginService } from 'src/app/services/login.service';
import { MasterService } from 'src/app/services/master.service';
import { PopoverService } from '../../../components/popover/popover.service';

@Component({
  selector: 'vex-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./toolbar-user.component.scss'],
})
export class ToolbarUserComponent implements OnInit {

  dropdownOpen: boolean;
  userName : string;
  public titlename :any;
  constructor(private loginService: LoginService,
              public dialog: MatDialog,
              private materService: MasterService, private ref: ChangeDetectorRef) { 
    let userDetails = this.materService.getUserDetails();
    this.userName = userDetails ? userDetails.user_name : ""; 
 
  
  }
  ngOnInit() {
    this.materService.titleName$.subscribe((items) => {
      this.titlename = items
      this.ref.detectChanges();
    });
  }

  Logout() {
    this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '400px'
    }).afterClosed().subscribe(result => {
      if(result == 'Yes') {
        this.loginService.Logout();
      }
    });
  }
}
