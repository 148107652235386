import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatCardModule} from '@angular/material/card';
import { NgApexchartsModule } from "ng-apexcharts";
import {MatDividerModule} from '@angular/material/divider';
import { ConfigPanelModule } from 'src/@vex/components/config-panel/config-panel.module';
import { PageLayoutModule } from '../../../../@vex/components/page-layout/page-layout.module';
import { ConstituencyFilterModule } from '../constituency-filter/constituency-filter.module';
import { SidebarModule } from '../../../../@vex/components/sidebar/sidebar.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
@NgModule({
  declarations: [
    DashboardComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    FlexLayoutModule,
    PageLayoutModule,
    NgApexchartsModule,
    MatCardModule,
    MatDividerModule,
    MatButtonModule,
    ConfigPanelModule,
    ConstituencyFilterModule,
    SidebarModule,
    MatProgressSpinnerModule
    
  ]
})
export class DashboardModule { }
