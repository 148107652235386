import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BcmmappingListRoutingModule } from './bcmmapping-list-routing.module';
import { BcmmappingListComponent } from './bcmmapping-list.component';  
 
import { PageLayoutModule } from '../../../../@vex/components/page-layout/page-layout.module';
import { BreadcrumbsModule } from '../../../../@vex/components/breadcrumbs/breadcrumbs.module';
import { WidgetCardModule } from '../../../../app/pages/apps/widget-card/widget-card.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {WidgetQuickValueCenterModule } from '../../../../@vex/components/widgets/widget-quick-value-center/widget-quick-value-center.module'
import { ConstituencyFilterModule } from '../constituency-filter/constituency-filter.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SidebarModule } from '../../../../@vex/components/sidebar/sidebar.module';
import { ConfigPanelModule } from 'src/@vex/components/config-panel/config-panel.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
@NgModule({
  declarations: [
    BcmmappingListComponent
  ],
  imports: [
    MatProgressSpinnerModule,
    CommonModule,SidebarModule,
    BcmmappingListRoutingModule,PageLayoutModule,BreadcrumbsModule,WidgetCardModule,FlexLayoutModule
    ,MatPaginatorModule,MatTableModule,MatSortModule,MatCheckboxModule,MatIconModule,
    MatButtonModule,MatMenuModule,FormsModule, ReactiveFormsModule,MatTooltipModule,MatSelectModule
    ,MatButtonToggleModule,WidgetQuickValueCenterModule,MatAutocompleteModule,MatInputModule,ConstituencyFilterModule,ConfigPanelModule
  ]
})
export class BcmmappingListModule { 
  
}
