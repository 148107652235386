<div  fxLayout="row" fxflex="100%"  fxLayoutAlign="space-between center" >
<div fxLayout="row" fxflex="22%"  >
<h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none fontsize" >
   {{titlename}}  
</h2>
</div>
<div #originRef [matMenuTriggerFor]="accountMenu" [class.bg-hover]="dropdownOpen"
fxLayout="row"  class="flex items-center rounded cursor-pointer relative trans-ease-out select-none py-1 pr-1 pl-3 hover:bg-hover"
  matRipple  >
  <div class="body-1 font-medium leading-snug ltr:mr-3 rtl:ml-3 hidden sm:block">{{ userName }}</div>
  <div class="rounded-full h-9 w-9 flex items-center justify-center text-primary bg-primary/10">
    <mat-icon svgIcon="mat:person"></mat-icon>
  </div>
</div>

<mat-menu #accountMenu [overlapTrigger]="false" xPosition="before" class="matmenu_header">
  <button mat-menu-item (click)="Logout()">
    <mat-icon svgIcon="mat:logout"></mat-icon>
    <span>Logout</span>
  </button>
</mat-menu>
</div>