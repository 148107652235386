import { Component, ElementRef, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MasterService } from 'src/app/services/master.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAccordion } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'vex-constituency-filter',
  templateUrl: './constituency-filter.component.html',
  styleUrls: ['./constituency-filter.component.scss']
})
export class ConstituencyFilterComponent {
  panelOpenState = false;
  stateObserb!: Observable<any[]>;
  zoneObserb!: Observable<any[]>;
  boothObserb!: Observable<any[]>;
  constituencyObserb!: Observable<any[]>;

  public filterform!: FormGroup;
  public additionalFilter!: FormGroup;
  public multiplestatue: any[] = [];
  public multipleConstituency: any[] = [];
  @Output() public IdDetails: EventEmitter<any> = new EventEmitter<any>(); //emit an Edit Required function
  @Output() public closeConfig: EventEmitter<boolean> = new EventEmitter<boolean>(false); //emit an Edit Required function
  @Input() boothVisible: boolean = true;
  @Input() filteralignment: boolean = true;
  @Input() wardVisible: boolean = true;
  @Input() AdditionalFilter: boolean = false;
  filteredbooths: Observable<any[]>;
  filteredward: Observable<any[]>;
  booths: string[] = [];
  wards: string[] = [];
  BoothNoArray = [];
  wardNoArray = [];
  boothCtrl = new FormControl('');
  wardCtrl = new FormControl('');
  allbooths: any[];
  multipleBoothno: any[];
  multiWardList: any[];
  totalBoothList: any[] = [];
  totalwardList: any[] = [];
  genderList: any[] = [];
  zoneList: any[] = [];
  verificationStatusList: any[] = [];
  @ViewChild('boothInput') boothInput: ElementRef<HTMLInputElement>;
  @ViewChild('wardInput') wardInput: ElementRef<HTMLInputElement>;
  @ViewChild('votersFilter', { static: false }) votersFilter: any;

  @ViewChild(MatAccordion) accordion: MatAccordion;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  voterStatusList: any[] = [];
  religionList: any[] = [];
  ageList: { label: string; value: string; label_ta: string; }[];
  educationList: any;
  professionTypeList: any;
  professionListjob: any;
  pensionList: any;
  ageFilterList: any[] =[];
  MasterResponse !:any;
  specialCases: any[] =[];
  partyMemberList: any[] =[];
  differentlyAbled: any[] =[];
  
  constructor(private formBuilder: FormBuilder, private masterService: MasterService,
    private snackbar: MatSnackBar) {
    this.filterform = this.formBuilder.group({        //Form Builder
      statename: [''],
      stateID: [''],
      constituency: [''],
      constituencyID: [''],
      booth: [''],
      zonename: [''],
      ZoneID: [''],
      ward: [''],
      zoneCode:[''],
      TitleLabel: [''],
      zoneSelect: [''],
    });
    this.additionalFilter = this.formBuilder.group({        //Form Builder
      ageFrom: [''],
      ageTo: [''],
      age: [''],
      eduQualification: [''],
      mobileno: [''],
      partyMember: [''],
      willingToJoin: [''],
      gender: [],
      verificationStatus: [],
      differentlyAbled: [],
      appliedPartyMember: [],
      voterStatus:[''],
      religions: [''],
      professionL1: [''],
      job: [''],
      pension:[''],
      specialCase: [0],
      AppliedFilter:[]
    });
  }

  ngOnInit() {
    this.masterService.streetDropdownList().subscribe((response: any) => {	
      this.MasterResponse=response;	
      this.initalBinding(response);
      localStorage.setItem("MASTER_LIST",JSON.stringify(response));
    })
    this.constituencyObserb = this.filterform.get('constituency').valueChanges.pipe(
      startWith(''),
      map(value => this._assigneesFilterconstitue(value))
    );
    this.stateObserb = this.filterform.get('statename').valueChanges.pipe(
      startWith(''),
      map(value => this._assigneesFilter(value))
    );
    this.zoneObserb = this.filterform.get('zonename').valueChanges.pipe(
      startWith(''),
      map(value => this.zone_Filter(value))
    );
  }

  initalBinding(response:any) {
    if (response && response.body && response.body.state_list) {
      this.multiplestatue = response.body.state_list;
      this.zoneList = response.body.zone_list ? [...[{
        "type": "NORMAL",
        "key": 1,
        "zone_code": 0,
        "zone_name": "All",
        "zone_name_ta": "All",
        "constituency_code": 1
    }],...response.body.zone_list]: [];

      this.multipleConstituency = response.body.constituency_list.filter((el: any) => this.multiplestatue[0].state_code == el.state_code);
      this.filterform.patchValue({
        statename: this.multiplestatue[0].state_name,
        stateID: this.multiplestatue[0].state_code,
        constituency: this.multipleConstituency[0].constituency_name,
        constituencyID: this.multipleConstituency[0].constituency_code,
        zoneCode: "",
        ZoneID: 0,
        zonename: "",
        zoneSelect: "All",
        TitleLabel: this.multiplestatue[0].state_name
      });
      
      this.totalBoothList = response.body.booth_list;
      this.multipleBoothno = this.totalBoothList;
      this.totalwardList = response.body.wardList;
      this.multiWardList = this.totalwardList;
      this.genderList = response.body.gender_list ? response.body.gender_list : [];
      this.verificationStatusList = response.body.verificationStatusList ? response.body.verificationStatusList : [];
      this.voterStatusList = response.body.voterStatusList ? response.body.voterStatusList : [];
      this.religionList = response.body.religionList ? response.body.religionList : [];
      this.educationList = response.body.educationList ? response.body.educationList : [];
      this.professionTypeList = response.body.professionTypeList ? response.body.professionTypeList : [];
      this.professionListjob = response.body.professionList ? response.body.professionList : [];
      this.pensionList = response.body.pensionList ? response.body.pensionList : [];
      this.ageFilterList= response.body.ageFilterList ? response.body.ageFilterList : [];
      
      this.specialCases = response.body.specialcase ? response.body.specialcase : [];
      this.partyMemberList = response.body.partyMember ? response.body.partyMember : [];
      this.differentlyAbled = response.body.diifferentlyAbled ? response.body.diifferentlyAbled : [];
      if(!this.AdditionalFilter)
        this.IdDetails.emit(this.filterform);
      else
        this.IdDetails.emit({filter:this.filterform, additional: this.additionalFilter});

        
      if (this.votersFilter)
      this.votersFilter.bindDetails({
        genderList: this.genderList,
        verificationStatusList: this.verificationStatusList,
        voterStatus: this.voterStatusList,
        religionList: this.religionList,
        ageList : this.ageFilterList,
        educationList: this.educationList,
        professionTypeList: this.professionTypeList, //profession
        job: this.professionListjob, //job
        pension: this.pensionList,
        specialCases: this.specialCases,
        partyMemberList: this.partyMemberList,
        differentlyAbled: this.differentlyAbled
      });
    }
    else
      this.IdDetails.emit(this.filterform);

      this.loadWard();
      this.loadBooth();
  }

  loadstate()       //Load the clients when focus on input
  {
    this.stateObserb = this.filterform.get('statename').valueChanges.pipe(
      startWith(''),
      map(value => this._assigneesFilter(value))
    );
  }
  loadZone() {
    this.zoneObserb = this.filterform.get('zonename').valueChanges.pipe(
      startWith(''),
      map(value => this.zone_Filter(value))
    );
  }
  private _assigneesFilterconstitue(value: string): any[] {
    if (value != null) {
      const filter = value.toLowerCase();
      return this.multipleConstituency.filter((option: any) => String(option.constituency_name).toLowerCase().includes(filter));
    }
  }

  loadconstituency()       //Load the clients when focus on input
  {
    this.constituencyObserb = this.filterform.get('constituency').valueChanges.pipe(
      startWith(''),
      map(value => this._assigneesFilterconstitue(value))
    );
  }

  remove(booth: string): void {
    const index = this.booths.indexOf(booth);

    if (index >= 0) {
      this.BoothNoArray[index] ? this.multipleBoothno.push(this.BoothNoArray[index]) : [];
      this.BoothNoArray.splice(index, 1);
      this.booths.splice(index, 1);
      this.multipleBoothno.sort((a: any, b: any) => {  //sorting
        if (Number(a.bth_booth_code) > Number(b.bth_booth_code))
            return 1;
        else
            return -1;
    });
    this.loadBooth();
    }
  }

  removeWard(ward: string): void {//d
    const index = this.wards.indexOf(ward);

    if (index >= 0) {
      if(this.wardNoArray[index] && this.wardNoArray[index].ward_code)
        this.multipleBoothno = this.multipleBoothno.filter((el: any) => this.wardNoArray[index].ward_code == el.party_ward_code);
        
      this.wardNoArray[index] ? this.multiWardList.push(this.wardNoArray[index]) : [];
      this.wardNoArray.splice(index, 1);
      this.wards.splice(index, 1);
      // this.totalBoothList.filter((el: any) => event.option.value.ward_code == el.party_ward_code);
      this.allbooths = [];//d
      this.booths = [];
      this.boothInput.nativeElement.value = '';
      this.boothCtrl.setValue(null);
      this.BoothNoArray = [];
      this.multiWardList.sort((a: any, b: any) => {  //sorting
        if (Number(a.ward_code) > Number(b.ward_code))
            return 1;
        else
            return -1;
    });
      this.loadWard();
      this.loadBooth();
    }
  }

  filterEmit() {
    this.filterform.patchValue({
      booth: this.BoothNoArray.flatMap((el: any) => el.bth_booth_code),
      ward: this.wardNoArray.flatMap((el: any) => el.ward_code),
    })
    this.additionalFilter.patchValue({
      verificationStatus: this.additionalFilter.value.verificationStatus || [],
      gender: this.additionalFilter.value.gender || [],
      voterStatus: this.additionalFilter.value.voterStatus || [],
      religions: this.additionalFilter.value.religions || [],
      eduQualification: this.additionalFilter.value.eduQualification || [],
      age: this.additionalFilter.value.age || [],
      professionL1: this.additionalFilter.value.professionL1 || [],
    })
    this.additionalFilter.patchValue({
      booth: this.BoothNoArray.flatMap((el: any) => el.bth_booth_code),
      ward: this.wardNoArray.flatMap((el: any) => el.ward_name),
    })
    if (this.AdditionalFilter) {
      if ((this.additionalFilter.value.ageFrom != "" && this.additionalFilter.value.ageTo == "") || this.additionalFilter.value.ageFrom == "" && this.additionalFilter.value.ageTo != "") {
        this.snackbar.open("Kindly fill the age limit", "", {
          duration: 3000, verticalPosition: 'top', horizontalPosition: 'right', panelClass: ['custom-snackbar-danger']
        });
        return;
      }
      if (Number(this.additionalFilter.value.ageFrom) > Number(this.additionalFilter.value.ageTo)) {
        this.snackbar.open("Kindly fill the valid age limit", "", {
          duration: 3000, verticalPosition: 'top', horizontalPosition: 'right', panelClass: ['custom-snackbar-danger']
        });
        return
      }
      this.IdDetails.emit({ filter: this.filterform, additional: this.additionalFilter });
    }
    else
      this.IdDetails.emit(this.filterform);
    this.closeConfig.emit(true)
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.booths.push(event.option.viewValue.replace('Booth - ', ''));
    this.boothInput.nativeElement.value = '';
    this.boothCtrl.setValue(null);
    this.BoothNoArray.push(event.option.value);
    this.multipleBoothno = this.multipleBoothno.filter(ward => String(ward.bth_booth_code) != event.option.value.bth_booth_code);
    this.multipleBoothno = this.multipleBoothno.sort((a,b)=>Number(String(a.bth_booth_code).replace("Booth - ",""))-Number(String(b.bth_booth_code).replace("Booth - ","")))    
    this.loadBooth()
  }

  selectedWard(event: MatAutocompleteSelectedEvent): void {
    this.wards.push(event.option.viewValue);
    this.wardInput.nativeElement.value = '';
    this.wardCtrl.setValue(null);
    this.wardNoArray.push(event.option.value);
    this.multiWardList = this.multiWardList.filter(ward => String(ward.ward_code) != event.option.value.ward_code);
    this.multipleBoothno=[];
    for(let i=0;i<this.wardNoArray.length; i++) {
      let boothList = this.totalBoothList.filter((el: any) => this.wardNoArray[i].ward_code == el.party_ward_code);

      if(boothList && boothList.length > 0){
        this.multipleBoothno = [...this.multipleBoothno, ...boothList];
      }
    }
    this.multipleBoothno = this.multipleBoothno.sort((a,b)=>Number(String(a.bth_booth_code).replace("Booth - ",""))-Number(String(b.bth_booth_code).replace("Booth - ","")));  
    this.loadWard();
    this.loadBooth();
  }

  loadWard() {
    this.filteredward = this.wardCtrl.valueChanges.pipe(
      startWith(''),
      map((ward: string | null) => (this.Wardfilter(ward))),
    );
  }

  loadBooth() {
    this.filteredbooths = this.boothCtrl.valueChanges.pipe(
      startWith(''),
      map((booth: string | null) => this._filter(booth) ),
    );
  }
  private _filter(value: any): any[] {
    const filterValue = value && value.bth_booth_code ? value.bth_booth_code : value ? value.toLowerCase() : "";
    if(value && !value.bth_booth_code && filterValue != '') {
      filterValue.replace('b','');
      filterValue.replace('o','');
      filterValue.replace('t','');
      filterValue.replace('h','');
      filterValue.replace('-','');
      filterValue.replace(' ','');

    }
    return this.multipleBoothno.filter(booth => String(booth.booth_name).toLowerCase().includes(filterValue));
  }
  private Wardfilter(value: any): any[] {
    const filterWardValue = value && value.ward_name ? value.ward_name.toLowerCase() : value ? value.toLowerCase() : "";
    return this.multiWardList.filter(ward => String(ward.ward_name).toLowerCase().includes(filterWardValue));
  }


  changeState(client: any) {
    const filterValue = client.toLowerCase();
    let stateFilter = this.multiplestatue.filter((option: any) => String(option.state_name).toLowerCase() == (filterValue));
    if (stateFilter && stateFilter.length > 0) {
      this.filterform.patchValue({
        stateID: stateFilter[0].state_code
      });
    }
  }
  changeZoneValue(zoneValue: any) {
    // const filterValue = zoneValue.toLowerCase();
    if(!zoneValue.value)
      return;
    let zoneFilter = this.zoneList.filter((option: any) => option.zone_name == (zoneValue.value));
    if (zoneFilter && zoneFilter.length > 0) {
      this.filterform.patchValue({
        ZoneID: zoneFilter[0].zone_code,
        zonename: zoneValue.value == 'All' ? '' : zoneValue.value,
        zoneSelect: zoneValue.value,
      });

      if(zoneValue.value == 'All') {
        this.multiWardList = this.totalwardList;
      }
      else {
        this.wardNoArray = [];
        this.wards=[];
        this.loadWard();
  
        this.multiWardList = this.totalwardList.filter((el: any) => zoneFilter[0].zone_code == el.zone_code);
        this.multipleBoothno = [];
        this.booths = [];
        this.BoothNoArray = [];
        this.boothCtrl.setValue(null);
      }
      
      this.filteredward = this.wardCtrl.valueChanges.pipe(
        startWith(''),
        map((ward: string | null) => (ward ? this.Wardfilter(ward) : this.multiWardList.length > 0 ? this.multiWardList.slice() : [])),
      );
    }  
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our booth
    if (value) {
      this.booths.push(value);
      this.BoothNoArray.push(value)
    }

    // Clear the input value
    event.chipInput!.clear();

    this.boothCtrl.setValue(null);
  }
  addWard(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our booth
    if (value) {
      this.wards.push(value);
      this.wardNoArray.push(value)
    }

    // Clear the input value
    event.chipInput!.clear();

    this.wardCtrl.setValue(null);
  }
  changeconstituency(client: any) {
    const filterValue = client.toLowerCase();
    let ConstituencyFilter = this.multipleConstituency.filter((option: any) => String(option.constituency_name).toLowerCase() == (filterValue));

    if (ConstituencyFilter && ConstituencyFilter.length > 0) {
      this.filterform.patchValue({
        constituencyID: ConstituencyFilter[0].constituency_code
      });
    }
  }
  private _assigneesFilter(value: string): any[] {
    if (value != null) {
      const filterValue = value.toLowerCase();
      return this.multiplestatue.filter((option: any) => String(option.state_name).toLowerCase().includes(filterValue));
    }
  }
  private zone_Filter(value: string): any[] {
    if (value != null) {
      const filterValue = value.toLowerCase();
      return this.zoneList.filter((option: any) => String(option.zonename).toLowerCase().includes(filterValue));
    }
  }

  votersSubFilter($value: any) {
    this.additionalFilter.patchValue({
      ageFrom: $value.ageFrom,
      ageTo: $value.ageTo,
      eduQualification: $value.eduQualification && $value.eduQualification.length > 0 ? $value.eduQualification.flatMap((el: any) => el.code) : [],
      age: $value.age && $value.age.length > 0 ? $value.age.flatMap((el: any) => el.val) : [],
      mobileno: [''],
      partyMember: $value.partyMember && $value.partyMember.length > 0 ? $value.partyMember.flatMap((el: any) => el.code) : [],
      willingToJoin: $value.willingToJoin,
      gender: $value.gender && $value.gender.length > 0 ? $value.gender.flatMap((el: any) => el.code) : [],
      verificationStatus: $value.verificationStatus && $value.verificationStatus.length > 0 ? $value.verificationStatus.flatMap((el: any) => el.code) : [],
      differentlyAbled: $value.differentlyAbledCode || 0,
      appliedPartyMember: $value.appliedPartyMember,
      religions: $value.religions && $value.religions.length > 0 ? $value.religions.flatMap((el: any) => el.code) : [],
      voterStatus: $value.voterStatus && $value.voterStatus.length > 0 ? $value.voterStatus.flatMap((el: any) => el.code) : [],
      professionL1: $value.professionL1 && $value.professionL1.length > 0 ? $value.professionL1.flatMap((el: any) => el.code) : [],
      job: $value.job && $value.job.length > 0 ? $value.job.flatMap((el: any) => el.code) : [],
      pension: $value.pension && $value.pension.length > 0 ? $value.pension.flatMap((el: any) => el.code) : [],
      specialCase: $value.specialCaseCode ? $value.specialCaseCode : 0,
      AppliedFilter : $value
    });
  } 

  resetFilter() {
    this.booths = [];
    this.wards = [];
    this.BoothNoArray = [];
    this.wardNoArray = [];
    this.boothCtrl.setValue(null);
    this.wardCtrl.setValue(null);
    this.allbooths = [];
    this.multipleBoothno = [];
    this.multiWardList = [];
    // this.boothInput.nativeElement.value = '';
    // this.wardInput.nativeElement.value = '';

    this.filterform.patchValue({        //Form Builder
      statename: '',
      stateID: '',
      constituency: '',
      constituencyID: '',
      booth: '',
      zonename: '',
      ZoneID: '',
      ward: '',
      zoneCode:''
    });
    this.additionalFilter.patchValue({        //Form Builder
      ageFrom: '',
      ageTo: '',
      age: '',
      eduQualification: '',
      partyMember: '',
      willingToJoin: '',
      gender: '',
      verificationStatus: '',
      differentlyAbled: '',
      appliedPartyMember: '',
      voterStatus:'',
      religions: '',
      professionL1: '',
      job: '',
      pension:''
    });
    this.initalBinding(this.MasterResponse);
    
    this.constituencyObserb = this.filterform.get('constituency').valueChanges.pipe(
      startWith(''),
      map(value => this._assigneesFilterconstitue(value))
    );
    this.stateObserb = this.filterform.get('statename').valueChanges.pipe(
      startWith(''),
      map(value => this._assigneesFilter(value))
    );
    this.zoneObserb = this.filterform.get('zonename').valueChanges.pipe(
      startWith(''),
      map(value => this.zone_Filter(value))
    );
  }

  closeConfigure() {
    this.closeConfig.emit(true)
  }
}

const differentlyAbled= [
  {
    "type": "NORMAL",
    "code": -1,
    "name": "All",
  },
  {
      "type": "NORMAL",
      "code": 1,
      "name": "Differently Abled Man",
  },
  {
      "type": "NORMAL",
      "code": 2,
      "name": "Differently Abled Woman",
  },
  {
      "type": "NORMAL",
      "code": 3,
      "name": "Differently Abled Man & Woman",
  },
  {
      "type": "NORMAL",
      "code": 4,
      "name": "None",
  }
]