<!-- <div class="font-medium ltr:ml-4 rtl:mr-4 hidden sm:block">
  <div class="copyright pull-right">
    &copy;
    {{test | date: 'yyyy'}}, Powered by
    Trio-s Software Consultancy pvt.Ltd
</div>
</div> -->
<div class="footer flex">
  <div class="flex-auto flex items-center container">
   
    <!-- <div class="font-medium ltr:ml-4 rtl:mr-4 hidden sm:block">
      &copy;
    {{test | date: 'yyyy'}}, Powered by
    Trio-s Software Consultancy pvt.Ltd
    </div> -->
  </div>
</div>
