import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConstituencyFilterComponent } from './constituency-filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatListModule} from '@angular/material/list';
import {MatChipsModule} from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion'; 
import { VotersFilterModule } from '../voters-filter/voters-filter.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {MatSelectModule} from '@angular/material/select';

@NgModule({
  declarations: [
    ConstituencyFilterComponent
  ],
  imports: [
    CommonModule,
    VotersFilterModule,
    MatChipsModule,
    MatListModule,
    MatFormFieldModule,
    FlexLayoutModule,
    MatInputModule,
    MatAutocompleteModule,
    FormsModule, 
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatSelectModule
  ],
  exports:[
    ConstituencyFilterComponent
  ]
})
export class ConstituencyFilterModule { }
