import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MenuItem } from '../interfaces/menu-item.interface';
import { trackById } from '../../../../utils/track-by';
import { PopoverRef } from '../../../../components/popover/popover-ref';
import { ConfirmDialogComponent } from 'src/app/pages/apps/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
export interface OnlineStatus {
  id: 'online' | 'away' | 'dnd' | 'offline';
  label: string;
  icon: string;
  colorClass: string;
}

@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserDropdownComponent implements OnInit {
  public dialog_flag: number = 0;
   showLogoutDropdown = true;
  result: string;
  items: MenuItem[] = [
    {
      id: '1',
      icon: 'mat:account_circle',
      label: 'My Profile',
      description: 'Personal Information',
      colorClass: 'text-teal',
      route: '/apps/social'
    }, 
  ];

  statuses: OnlineStatus[] = [ 
  ];

  activeStatus: OnlineStatus = this.statuses[0];

  trackById = trackById;

  constructor(private cd: ChangeDetectorRef, public dialog: MatDialog,
              private popoverRef: PopoverRef<ToolbarUserDropdownComponent>) { }

  ngOnInit() {
  }

  setStatus(status: OnlineStatus) {
    this.activeStatus = status;
    this.cd.markForCheck();
  }

  close() { 
    
  this.dialog.open(ConfirmDialogComponent, {
    disableClose: false,
    width: '400px'
  }).afterClosed().subscribe(result => {
    this.result = result;
  });
  this.showLogoutDropdown = false;
}


}
