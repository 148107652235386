import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetCardComponent } from './widget-card.component';

import { MatIconModule } from '@angular/material/icon';


@NgModule({
  declarations: [
    WidgetCardComponent
  ],
  imports: [
    CommonModule,MatIconModule
  ]
})
export class WidgetCardModule { }
