import { Component , Input, OnInit} from '@angular/core';

@Component({
  selector: 'vex-widget-card',
  templateUrl: './widget-card.component.html',
  styleUrls: ['./widget-card.component.scss']
})
export class WidgetCardComponent {

  showButton: boolean;
   
  @Input() icon: string;
  @Input() iconClass: string;
  @Input() value: string;
  @Input() label: string;
}
