import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from './confirm-dialog.component';

import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';

import { MatDialogModule } from '@angular/material/dialog'; 
@NgModule({
  declarations: [
    ConfirmDialogComponent
  ],
  imports: [
    CommonModule,MatIconModule,MatButtonModule,FlexLayoutModule,MatDialogModule
  ],
  exports:[ConfirmDialogComponent]
})
export class ConfirmDialogModule { }
