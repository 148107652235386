<form [formGroup]="filterform" fxLayout="column" fxFlex="100%" fxLayoutGap="18px">
  <mat-form-field class="fw-400 fs-10 wt-180 cl-black pad-rig matSelectpad" appearance="outline"
    autocomplete="off" fxFlex="100%">
    <mat-label>Verification Status</mat-label>
    <mat-chip-grid #chipverf aria-label="Verification">
      <mat-chip-row *ngFor="let verf of verificate" (removed)="remove(verf,'verifications')">
        <div class="category-chip__text"> {{verf}} </div>
        <button matChipRemove [attr.aria-label]="'remove ' + verf">
          <mat-icon svgIcon="mat:cancel"></mat-icon>
        </button>
      </mat-chip-row>
    </mat-chip-grid>
    <input placeholder="Verification Status" #verifInput [formControl]="verfCtrl" [matChipInputFor]="chipverf"
      [matAutocomplete]="autoverif" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event,'verifications')" />
    <mat-autocomplete #autoverif="matAutocomplete" (optionSelected)="selected($event,'verifications')">
      <mat-option *ngFor="let verfi of filteredVerf | async" [value]="verfi">
        {{verfi.name}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>


  <mat-form-field class="fw-400 fs-10 wt-180 cl-black pad-rig matSelectpad" appearance="outline"
  autocomplete="off" fxFlex="100%">
  <mat-label>Voter Status</mat-label>
  <mat-chip-grid #chipvot aria-label="voters">
    <mat-chip-row *ngFor="let verf of votersStatus" (removed)="remove(verf,'voters')">
      <div class="category-chip__text"> {{verf}} </div>
      <button matChipRemove [attr.aria-label]="'remove ' + verf">
        <mat-icon svgIcon="mat:cancel"></mat-icon>
      </button>
    </mat-chip-row>
  </mat-chip-grid>
  <input placeholder="Verification Status" #voterInput [formControl]="voterCtrl" [matChipInputFor]="chipvot"
    [matAutocomplete]="autovoter" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    (matChipInputTokenEnd)="add($event,'voters')" />
  <mat-autocomplete #autovoter="matAutocomplete" (optionSelected)="selected($event,'voters')">
    <mat-option *ngFor="let voter of filteredVoter | async" [value]="voter">
      {{voter.name}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>



<mat-form-field class="fw-400 fs-10 ht-34 wt-180 cl-black pad-rig matSelectpad" appearance="outline"
autocomplete="off" fxFlex="100%">
<mat-label>Gender</mat-label>
<mat-chip-grid #chipGender aria-label="Gender No.">
  <mat-chip-row *ngFor="let gender of genders" (removed)="remove(gender,'gender')">
    <div class="category-chip__text"> {{gender}} </div>
    <button matChipRemove [attr.aria-label]="'remove ' + gender">
      <mat-icon svgIcon="mat:cancel"></mat-icon>
    </button>
  </mat-chip-row>
</mat-chip-grid>
<input placeholder="Gender" #genderInput [formControl]="genderCtrl" [matChipInputFor]="chipGender"
  [matAutocomplete]="autogender" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
  (matChipInputTokenEnd)="add($event,'gender')" />
<mat-autocomplete #autogender="matAutocomplete" (optionSelected)="selected($event,'gender')">
  <mat-option *ngFor="let gender of filteredGender | async" [value]="gender">
    {{gender.name}}
  </mat-option>
</mat-autocomplete>
</mat-form-field>

<mat-form-field class="fw-400 fs-10 ht-34 wt-180 cl-black pad-rig matSelectpad" appearance="outline" autocomplete="off" fxFlex="100%">
  <mat-label>Age </mat-label>
  <mat-chip-grid #chipAge aria-label="qua">
    <mat-chip-row *ngFor="let pension of ageDisp" (removed)="remove(pension,'age')">
      <div class="category-chip__text"> {{pension}} </div>
      <button matChipRemove [attr.aria-label]="'remove ' + pension">
        <mat-icon svgIcon="mat:cancel"></mat-icon>
      </button>
    </mat-chip-row>
  </mat-chip-grid>
  <input placeholder="Age" #AgeInput [formControl]="ageCtrl" [matChipInputFor]="chipAge"
    [matAutocomplete]="autoAge" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    (matChipInputTokenEnd)="add($event,'age')" />
  <mat-autocomplete #autoAge="matAutocomplete" (optionSelected)="selected($event,'age')">
    <mat-option *ngFor="let age of filteredAge | async" [value]="age">
      {{age.name}}
    </mat-option>
  </mat-autocomplete>
  </mat-form-field>

<mat-form-field class="fw-400 fs-10 ht-34 wt-180 cl-black pad-rig matSelectpad" appearance="outline" autocomplete="off" fxFlex="100%">
<mat-label>Religion</mat-label>
<mat-chip-grid #chipReligion aria-label="Religion">
  <mat-chip-row *ngFor="let religion of religions" (removed)="remove(religion,'religion')">
    <div class="category-chip__text"> {{religion}} </div>
    <button matChipRemove [attr.aria-label]="'remove ' + religion">
      <mat-icon svgIcon="mat:cancel"></mat-icon>
    </button>
  </mat-chip-row>
</mat-chip-grid>
<input placeholder="Religion" #religionInput [formControl]="religionCtrl" [matChipInputFor]="chipReligion"
  [matAutocomplete]="autoreligion" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
  (matChipInputTokenEnd)="add($event,'religion')" />
<mat-autocomplete #autoreligion="matAutocomplete" (optionSelected)="selected($event,'religion')">
  <mat-option *ngFor="let religion of filteredReligion | async" [value]="religion">
    {{religion.name}}
  </mat-option>
</mat-autocomplete>
</mat-form-field>


<mat-form-field class="fw-400 fs-10 ht-34 wt-180 cl-black pad-rig matSelectpad" appearance="outline" autocomplete="off" fxFlex="100%">
  <mat-label>Educational Qualification</mat-label>
  <mat-chip-grid #chipEdu aria-label="qua">
    <mat-chip-row *ngFor="let eduq of eduQualif" (removed)="remove(eduq,'eduQualif')">
      <div class="category-chip__text"> {{eduq}} </div>
      <button matChipRemove [attr.aria-label]="'remove ' + eduq">
        <mat-icon svgIcon="mat:cancel"></mat-icon>
      </button>
    </mat-chip-row>
  </mat-chip-grid>
  <input placeholder="Educational Qualification" #eduInput [formControl]="eduCtrl" [matChipInputFor]="chipEdu"
    [matAutocomplete]="autoEdu" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    (matChipInputTokenEnd)="add($event,'eduQualif')" />
  <mat-autocomplete #autoEdu="matAutocomplete" (optionSelected)="selected($event,'eduQualif')">
    <mat-option *ngFor="let eduqualif of filteredEdu | async" [value]="eduqualif">
      {{eduqualif.name}}
    </mat-option>
  </mat-autocomplete>
  </mat-form-field>
  
  <mat-form-field class="fw-400 fs-10 ht-34 wt-180 cl-black pad-rig matSelectpad" appearance="outline" autocomplete="off" fxFlex="100%">
    <mat-label>Profession</mat-label>
    <mat-chip-grid #chipPro aria-label="qua">
      <mat-chip-row *ngFor="let pref of profession" (removed)="remove(pref,'profession')">
        <div class="category-chip__text"> {{pref}} </div>
        <button matChipRemove [attr.aria-label]="'remove ' + pref">
          <mat-icon svgIcon="mat:cancel"></mat-icon>
        </button>
      </mat-chip-row>
    </mat-chip-grid>
    <input placeholder="Profession" #ProfessInput [formControl]="professCtrl" [matChipInputFor]="chipPro"
      [matAutocomplete]="autoProfess" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event,'profession')" />
    <mat-autocomplete #autoProfess="matAutocomplete" (optionSelected)="selected($event,'profession')">
      <mat-option *ngFor="let profess of filteredProfession | async" [value]="profess">
        {{profess.name}}
      </mat-option>
    </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="fw-400 fs-10 ht-34 wt-180 cl-black pad-rig matSelectpad" appearance="outline" autocomplete="off" fxFlex="100%">
      <mat-label>Job</mat-label>
      <mat-chip-grid #chipJob aria-label="qua">
        <mat-chip-row *ngFor="let pref of professionjob" (removed)="remove(pref,'job')">
          <div class="category-chip__text"> {{pref}} </div>
          <button matChipRemove [attr.aria-label]="'remove ' + pref">
            <mat-icon svgIcon="mat:cancel"></mat-icon>
          </button>
        </mat-chip-row>
      </mat-chip-grid>
      <input placeholder="Job" #JobInput [formControl]="jobCtrl" [matChipInputFor]="chipJob"
        [matAutocomplete]="autoJob" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event,'job')" />
      <mat-autocomplete #autoJob="matAutocomplete" (optionSelected)="selected($event,'job')">
        <mat-option *ngFor="let jobop of filteredJob | async" [value]="jobop">
          {{jobop.name}}
        </mat-option>
      </mat-autocomplete>
      </mat-form-field>

  <mat-form-field class="fw-400 fs-10 ht-34 wt-180 cl-black pad-rig matSelectpad" appearance="outline" autocomplete="off" fxFlex="100%">
    <mat-label>Party Member</mat-label>
    <mat-chip-grid #chipParty aria-label="qua">
      <mat-chip-row *ngFor="let party of partyMember" (removed)="remove(party,'party')">
        <div class="category-chip__text"> {{party}} </div>
        <button matChipRemove [attr.aria-label]="'remove ' + party">
          <mat-icon svgIcon="mat:cancel"></mat-icon>
        </button>
      </mat-chip-row>
    </mat-chip-grid>
    <input placeholder="Party Member" #PartyInput [formControl]="partyCtrl" [matChipInputFor]="chipParty"
      [matAutocomplete]="autoParty" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event,'party')" />
    <mat-autocomplete #autoParty="matAutocomplete" (optionSelected)="selected($event,'party')">
      <mat-option *ngFor="let party of filteredParty | async" [value]="party">
        {{party.label}}
      </mat-option>
    </mat-autocomplete>
    </mat-form-field>

  <mat-form-field class="fw-400 fs-10 ht-34 wt-180 cl-black pad-rig matSelectpad" appearance="outline" autocomplete="off" fxFlex="100%">
    <mat-label>Pension</mat-label>
    <mat-chip-grid #chipPension aria-label="qua">
      <mat-chip-row *ngFor="let pension of pensionDisp" (removed)="remove(pension,'pension')">
        <div class="category-chip__text"> {{pension}} </div>
        <button matChipRemove [attr.aria-label]="'remove ' + pension">
          <mat-icon svgIcon="mat:cancel"></mat-icon>
        </button>
      </mat-chip-row>
    </mat-chip-grid>
    <input placeholder="Pension" #PensionInput [formControl]="pensionCtrl" [matChipInputFor]="chipPension"
      [matAutocomplete]="autoPension" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event,'pension')" />
    <mat-autocomplete #autoPension="matAutocomplete" (optionSelected)="selected($event,'pension')">
      <mat-option *ngFor="let pension of filteredPension | async" [value]="pension">
        {{pension.name}}
      </mat-option>
    </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="fw-400 fs-10 ht-34 wt-180 cl-black pad-rig matSelectpad" appearance="outline"
            autocomplete="off" fxFlex="100%">
      <mat-label class="mat-client-label">Differently Abled</mat-label>
      <mat-select (valueChange)="changeDiffAbled(choosedvalue)" #choosedvalue formControlName="differentlyAbled" >
        <mat-option *ngFor="let diffOp of differentlyAbled" class="fs-10 ht-30"
        [value]="diffOp.label">{{diffOp.label}}
      </mat-option>
      </mat-select>
    </mat-form-field>  

    <!-- Zone filter -->
    <mat-form-field class="fw-400 fs-10 ht-34 wt-180 cl-black pad-rig matSelectpad" appearance="outline"
    autocomplete="off" fxFlex="100%">
    <mat-label class="mat-client-label">Special cases</mat-label>
    <input type="text" placeholder="Special cases" formControlName="specialCase" matInput
      [matAutocomplete]="specialauto" #autoCompleteInput (focus)="_filter('','special')" (input)="changeZone()">
    <mat-autocomplete autoActiveFirstOption #specialauto="matAutocomplete"
      (optionSelected)="changeZoneValue($event.option.value)">
      <mat-option *ngFor="let special of specialObserb | async" class="fs-10 ht-30"
        [value]="special.label">{{special.label}}
      </mat-option>
    </mat-autocomplete>
    <mat-icon matSuffix class="select-arrow-icon">
      <img class="mrg-8" src="./assets/img/arrow.png"></mat-icon>
  </mat-form-field>    
</form>